import React, {lazy, Suspense} from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import GlobalStyles from '../styles/global';
import {Layout, PageWrapper} from '../styles/sales_page';

const SqueezeCover = lazy(() => import('../components/Aula/SqueezeCover'));
const SqueezeFeatures = lazy(() => import('../components/Aula/SqueezeFeatures'));
const SqueezeDetails = lazy(() => import('../components/Aula/SqueezeDetails'));
const SqueezeDisclaimer = lazy(() => import('../components/Aula/SqueezeDisclaimer'));


const SqueezePage = () => {
    const isSSR = typeof window === "undefined";

    return (
        <Layout>  
            <PageWrapper>
                <GlobalStyles />
                {
                !isSSR &&
                <Suspense fallback={<PropagateLoader />}>
                    <SqueezeCover />
                    <SqueezeFeatures />
                    <SqueezeDetails />
                    <SqueezeDisclaimer />
                </Suspense>
                }
            </PageWrapper>
        </Layout>
    )
}

export default SqueezePage;